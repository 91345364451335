@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, :after, :before {
    box-sizing: border-box;
}
html,body{
    height: 100%;
}
body {
    margin: 0;
    font-family: Lato,sans-serif;
    background: #f5f5f1;
    color: #6c7577;
    line-height: 1.6em;
    font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #544e4e;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    &:after, &:before, {
        content: '';
        display: table;
    }
    &:after{
        clear: both;
    }
}
a {
    color: inherit;
    text-decoration: none;
}
.content a{
	color: #000;
	&:hover{
		text-decoration: underline;
	}
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
/**
 * HEADER
 */
header {
    flex-basis: 100%;
    align-self: flex-start;
    background: #3c3e42;
    position: relative;
    > .container{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 20px 2em;
    }
    .search-box{
        flex-basis: 50%;
        -webkit-box-flex: 3;
        flex: 3;
        position: relative;
    }
    input[type=search] {
        border-radius: 35px;
        border: 1px solid #eceff5;
        line-height: 3em;
        display: inline-block;
        padding: 0 10px 0 20px;
        box-sizing: content-box;
        width: calc(100% - 30px);
        font-size: 16px;
    }
    input[type=search]+i {
        position: absolute;
        top: 13px;
        right: 10px;
        font-weight: 700;
        color: #5a6779;
    }
	nav.main-nav {
	    position: relative;
	    display: block;
	    width: 100%;
	    z-index: 10;
	    border-top: 1px solid #4e5156;
		ul{
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			li{
				display: inline;
			    float: left;
			    color: #fff;
			    font-size: 15px;
			    font-weight: 700;
			    -webkit-box-flex: 1;
			    flex: 1;
			    -webkit-box-align: center;
			    -ms-grid-row-align: center;
			    align-items: center;
			    border-right: 1px solid #4e5156;
			    text-align: center;
				a {
					display: block;
					padding: 13px 2em;
				}
				&:nth-child(2) {
    				border-left: 1px solid #4e5156;
				}
				&.active,&:hover{
					background: #13afdf;
					text-shadow: 3px 3px rgba( 0, 0, 0, 0.1 );
				}
			}
		}
	}
	.heading{
		background: #13afdf;
		color: #fff;
		padding: 60px 0;
		-webkit-box-flex: 1;
		flex: 1;
		flex-basis: 100%;
		h1 {
    		display: inline-block;
			margin-top: -10px;
			text-shadow: 3px 3px rgba( 0, 0, 0, 0.1 );
			max-width: 75%;
			font-size: 55px;
			font-weight: 800;
			line-height: 1.2;
			padding: 0;
			color: #fff;
		}
	}
}
.logo {
    margin: 0 32px 0 0;
    letter-spacing: -.3px;
    float: left;
    font-size: 31px;
    font-weight: 700;
    color: #fff;
    border-right: 1px solid #5a6779;
    padding-right: 30px;
    &:hover{
        color: #e6e6e6;
    }
}

/**
 * Utilities
 */
.centered {
	text-align: center;
}

/*
maps*/
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.pac-container {
  font-family: Roboto;
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

/**
 * PHotos
 */
.photos{
	padding: .5vw;
  font-size: 0;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: -webkit-box;
  display: flex;
}
.photos div{
	-webkit-box-flex: auto;
	-ms-flex: auto;
	flex: auto;
	width: 200px;
	margin: .5vw;
}
.photos div img{
	width: 100%;
  height: auto;
}

footer {
  background: #444;
  padding: 10px;
  color: #fff;
}
.country-box {
  background: #ccc;
  border-radius: 14px;
  padding: 5px 10px;
  display: inline-flex;
  margin-bottom: 13px;
  align-items: center;
  vertical-align: middle;
  margin-right: 5px;
}